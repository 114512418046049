import { template as template_ccfdf82823e4405f81462ca4dafa8bea } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_ccfdf82823e4405f81462ca4dafa8bea(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
