import { template as template_7dfaee6a6cbd4214af9bca6c5a897475 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7dfaee6a6cbd4214af9bca6c5a897475(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
