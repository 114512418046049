import { template as template_7fe1ac291266411684392f6fbfa22b26 } from "@ember/template-compiler";
const FKText = template_7fe1ac291266411684392f6fbfa22b26(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
